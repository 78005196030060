import { t } from 'i18next'
import { CampaignType, Vouchergrouptype } from '../../../generated/graphql'
import {
  CAMPAIGN_TYPE_CART,
  CAMPAIGN_TYPE_PACKAGE,
  CAMPAIGN_TYPE_PRICELIST,
  CAMPAIGN_TYPE_PRODUCT,
} from '../../../utils/campaigns'

export const translateCampaignType = (
  vouchertype: CampaignType | undefined | null,
): string => {
  if (!vouchertype) return ''

  if (CAMPAIGN_TYPE_CART.types.includes(vouchertype))
    return t(CAMPAIGN_TYPE_CART.name)

  if (CAMPAIGN_TYPE_PRODUCT.types.includes(vouchertype))
    return t(CAMPAIGN_TYPE_PRODUCT.name)

  if (CAMPAIGN_TYPE_PACKAGE.types.includes(vouchertype))
    return t(CAMPAIGN_TYPE_PACKAGE.name)

  if (CAMPAIGN_TYPE_PRICELIST.types.includes(vouchertype))
    return t(CAMPAIGN_TYPE_PRICELIST.name)

  return ''
}

type Params = {
  vouchertype: CampaignType
  tags: string
  value_x: string
  value_y: string
  value_z: string
  money_m: string
  money_n: string
}

export const translateCampaignTypeOffer = (params: Params): string => {
  const { vouchertype, ...tokens } = params

  switch (vouchertype) {
    case CampaignType.NUMBER_100:
      return t('campaigns:campaign_types_tokens.cart_discount_m', tokens)
    case CampaignType.NUMBER_110:
      return t('campaigns:campaign_types_tokens.cart_discount_x', tokens)
    case CampaignType.NUMBER_120:
      return t(
        'campaigns:campaign_types_tokens.shop_for_m_get_n_cart_discount',
        tokens,
      )
    case CampaignType.NUMBER_200:
      return t('campaigns:campaign_types_tokens.product_discount_m', tokens)
    case CampaignType.NUMBER_210:
      return t('campaigns:campaign_types_tokens.product_discount_x', tokens)
    case CampaignType.NUMBER_220:
      return t('campaigns:campaign_types_tokens.buy_x_for_m', tokens)
    case CampaignType.NUMBER_230:
      return t('campaigns:campaign_types_tokens.buy_x_pay_for_y', tokens)
    case CampaignType.NUMBER_240:
      return t(
        'campaigns:campaign_types_tokens.buy_x_pay_for_y_and_get_z_discount_on_rest',
        tokens,
      )
    case CampaignType.NUMBER_250:
      return t(
        'campaigns:campaign_types_tokens.buy_minimum_quantity_x_get_y_discount',
        tokens,
      )
    case CampaignType.NUMBER_260:
      return t(
        'campaigns:campaign_types_tokens.buy_x_get_discounts_from_list',
        tokens,
      )
    case CampaignType.NUMBER_265:
      return t(
        'campaigns:campaign_types_tokens.buy_for_m_get_discount_list',
        tokens,
      )
    case CampaignType.NUMBER_270:
      return t(
        'campaigns:campaign_types_tokens.buy_3_pay_2_or_buy_2_pay_1_half',
      )
    case CampaignType.NUMBER_300:
      return t('campaigns:campaign_types_tokens.package_price_m', tokens)
    case CampaignType.NUMBER_310:
      return t('campaigns:campaign_types_tokens.package_discount_x', tokens)
    case CampaignType.NUMBER_320:
      return t('campaigns:campaign_types_tokens.package_discount_m', tokens)
    case CampaignType.NUMBER_400:
      return t(
        'campaigns:campaign_types_tokens.activate_pricelists_tags',
        tokens,
      )
    case CampaignType.NUMBER_410:
      return t(
        'campaigns:campaign_types_tokens.activate_pricelists_block_other_tags',
        tokens,
      )

    default:
      return ''
  }
}

export const translateCampaignTypeTitle = (type?: CampaignType): string => {
  switch (type) {
    case CampaignType.NUMBER_100:
      return t('campaigns:campaign_types_titles.cart_discount_m')
    case CampaignType.NUMBER_110:
      return t('campaigns:campaign_types_titles.cart_discount_x')
    case CampaignType.NUMBER_120:
      return t('campaigns:campaign_types_titles.shop_for_m_get_n_cart_discount')
    case CampaignType.NUMBER_200:
      return t('campaigns:campaign_types_titles.product_discount_m')
    case CampaignType.NUMBER_210:
      return t('campaigns:campaign_types_titles.product_discount_x')
    case CampaignType.NUMBER_220:
      return t('campaigns:campaign_types_titles.buy_x_for_m')
    case CampaignType.NUMBER_230:
      return t('campaigns:campaign_types_titles.buy_x_pay_for_y')
    case CampaignType.NUMBER_240:
      return t(
        'campaigns:campaign_types_titles.buy_x_pay_for_y_and_get_z_discount_on_rest',
      )
    case CampaignType.NUMBER_250:
      return t(
        'campaigns:campaign_types_titles.buy_minimum_quantity_x_get_y_discount',
      )
    case CampaignType.NUMBER_260:
      return t('campaigns:campaign_types_titles.buy_x_get_discounts_from_list')
    case CampaignType.NUMBER_265:
      return t('campaigns:campaign_types_titles.buy_for_m_get_discount_list')
    case CampaignType.NUMBER_270:
      return t(
        'campaigns:campaign_types_titles.buy_3_pay_2_or_buy_2_pay_1_half',
      )
    case CampaignType.NUMBER_300:
      return t('campaigns:campaign_types_titles.package_price_m')
    case CampaignType.NUMBER_310:
      return t('campaigns:campaign_types_titles.package_discount_x')
    case CampaignType.NUMBER_320:
      return t('campaigns:campaign_types_titles.package_discount_m')
    case CampaignType.NUMBER_400:
      return t('campaigns:campaign_types_titles.activate_pricelists_tags')
    case CampaignType.NUMBER_410:
      return t(
        'campaigns:campaign_types_titles.activate_pricelists_block_other_tags',
      )

    default:
      return ''
  }
}

export const translateCampaignTypeExample = (type?: CampaignType): string => {
  switch (type) {
    case CampaignType.NUMBER_100:
      return t('campaigns:campaign_types_examples.cart_discount_m')
    case CampaignType.NUMBER_110:
      return t('campaigns:campaign_types_examples.cart_discount_x')
    case CampaignType.NUMBER_120:
      return t(
        'campaigns:campaign_types_examples.shop_for_m_get_n_cart_discount',
      )
    case CampaignType.NUMBER_200:
      return t('campaigns:campaign_types_examples.product_discount_m')
    case CampaignType.NUMBER_210:
      return t('campaigns:campaign_types_examples.product_discount_x')
    case CampaignType.NUMBER_220:
      return t('campaigns:campaign_types_examples.buy_x_for_m')
    case CampaignType.NUMBER_230:
      return t('campaigns:campaign_types_examples.buy_x_pay_for_y')
    case CampaignType.NUMBER_240:
      return t(
        'campaigns:campaign_types_examples.buy_x_pay_for_y_and_get_z_discount_on_rest',
      )
    case CampaignType.NUMBER_250:
      return t(
        'campaigns:campaign_types_examples.buy_minimum_quantity_x_get_y_discount',
      )
    case CampaignType.NUMBER_260:
      return t(
        'campaigns:campaign_types_examples.buy_x_get_discounts_from_list',
      )
    case CampaignType.NUMBER_265:
      return t('campaigns:campaign_types_examples.buy_for_m_get_discount_list')
    case CampaignType.NUMBER_270:
      return t(
        'campaigns:campaign_types_examples.buy_3_pay_2_or_buy_2_pay_1_half',
      )
    case CampaignType.NUMBER_300:
      return t('campaigns:campaign_types_examples.package_price_m')
    case CampaignType.NUMBER_310:
      return t('campaigns:campaign_types_examples.package_discount_x')
    case CampaignType.NUMBER_320:
      return t('campaigns:campaign_types_examples.package_discount_m')
    case CampaignType.NUMBER_400:
      return t('campaigns:campaign_types_examples.activate_pricelists_tags')
    case CampaignType.NUMBER_410:
      return t(
        'campaigns:campaign_types_examples.activate_pricelists_block_other_tags',
      )

    default:
      return ''
  }
}

export const translateCampaignGroupType = (
  voucherGroupType: Vouchergrouptype | undefined | null,
): string => {
  if (!voucherGroupType) return ''

  return t(`campaigns:campaign_group_type.${voucherGroupType}`)
}
